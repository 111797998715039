.hover-container {
    position: relative;
    display: inline-block;
}

.hover-content {
    display: none;
    position: absolute;
    top: 0%;
    left: 150%;
    transform: translateX(-50%);
    padding: 10px;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    z-index: 1;
    width: 250px; /* Adjust width as needed */
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.hover-container:hover .hover-content {
    display: block;
}
