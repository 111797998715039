.hover-container {
    /*position: relative;*/
    /*display: inline-block;*/
}

/* Position hover content above the text */
.hover-content-above {
    display: none;
    position: absolute;
    bottom: 100%; /* Position it just above the text */
    left: 50%;
    transform: translateX(-30%);
    padding: 10px;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    z-index: 1;
    width: 250px;
    text-align: left;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px; /* Optional spacing between text and hover content */
}

/* Position hover content below the text */
.hover-content-below {
    display: none;
    position: absolute;
    top: 100%; /* Position it just below the text */
    left: 50%;
    transform: translateX(-30%);
    padding: 10px;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    z-index: 1;
    width: 250px;
    text-align: left;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-top: 10px; /* Optional spacing between text and hover content */
}


div.hover-container:hover div.hover-content-above,
div.hover-container:hover div.hover-content-below {
    display: block;
}

.explanation-superscript {
    font-size: 0.7em;
    vertical-align: super;
    cursor: pointer;
    color: darkblue;
    font-weight: bolder;
}
